.DriverForm {
    margin-top: 48px;
    padding: 24px 32px;
    background-color: #ffffff;
    box-shadow: -6px 6px 20px 0 rgb(14 30 37 / 20%);
    border-radius: 16px;
}

.DriverForm .formContent {
    display: flex;
}

.DriverForm .formContent form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 16px;
}

.DriverForm .formActions {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}