.OverviewTable {
    margin-top: 48px;
    padding: 24px 32px;
    background-color: #ffffff;
    box-shadow: -6px 6px 20px 0 rgb(14 30 37 / 20%);
    border-radius: 16px;
}

.OverviewTable table {
    width: 100%;
}

.OverviewTable table {
    border-spacing: 0;
    text-align: left;
}

.OverviewTable table thead tr th {
    border-bottom: 1px solid #E7EAE1;
    border-spacing: 5px 5px;
    text-transform: uppercase;
    font-family: Gotham-regular, serif;
    line-height: 40px;
}

.OverviewTable table tbody tr:not(:last-child) td {
    border-bottom: 1px solid #E7EAE1;
}

.OverviewTable table tbody tr {
    line-height: 80px;
}

.OverviewTable {
    font-size: 16px;
}

.OverviewTable tbody tr td a {
    color: #FF453C;
    text-decoration: none;
    border-bottom: 1px solid;
}

.OverviewTable tbody tr td.action {
    text-align: center;
    width: 1%;
    white-space: nowrap;
}

.OverviewTable .loading div {
    margin: auto;
}

.OverviewTable .header-row h3 {
    margin: 0;
    font-family: Gotham-regular, serif;
}

.OverviewTable .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
}

.OverviewTable .header-row a {
    background-color: #F94340;
    padding: 20px 48px;
    color: #ffffff;
    text-decoration: none;
    border-radius: 40px;
    font-family: Gotham-regular, serif;
}