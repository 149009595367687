.FormSelect {
    display: flex;
    flex-direction: column;
}

.FormSelect select {
    margin-top: 4px;
    height: 50px;
    background-color: #ffffff;
    padding: 8px;
    font-size: 18px;
    color: #58606D;
    outline: none;
    border: 1px solid #D0D0D0;
    border-radius: 4px;
}