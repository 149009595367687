.login-route {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.login-route .absolute {
    position: absolute;
    display: inline-flex;
    width: 100%;
    height: 100%;
}

.login-route .left-container {
    width: 40%;
    background-color: #ffffff;
    padding: 4rem 6rem;
}

.login-route h2 {
    font-size: 40px;
    margin-bottom: 6%;
}

.login-route .logo {
    padding-bottom: 1rem;
    padding-left: 4px;
}

.login-route .input-container {
    padding-bottom: 2rem;
}

.login-route .input-container label {
    display: flex;
    flex-direction: column;
    font-family: Gotham-regular, serif;
    line-height: 2rem;
    font-size: 18px;
}

.login-route .input-container input {
    border: 1px solid #DADADA;
    line-height: 4rem;
    font-size: 18px;
    border-radius: 4px;
    padding-left: 12px;
}

.login-route .remember label {
    color: #aeaeae;
}

.login-route .right-container {
    width: 60%;
}

.login-route .gradient {
    margin-left: auto;
    background: linear-gradient(-135deg, rgba(242, 145, 45, 1) 0%, rgba(236, 62, 129, 1) 100%);
}

.login-route .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../../src/img/login_dodavka.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.login-route .login-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.login-route .LoginButton {
    width: 100%;
    height: 80px;
    margin-bottom: 8px;
}

.login-route .forgotten-password {
    color: #2f3b7c;
    font-family: Gotham-regular, serif;
    cursor: pointer;
}

.login-route .register {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    align-items: baseline;
}

.login-route .register p {
    font-family: Gotham-regular, serif;
    color: #aeaeae;
}

.login-route .right-container img {
    margin-top: 3rem;
    max-width: 20%;
}

.login-route .right-container {
    padding: 16rem 0 7rem 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.login-route .right-container * {
    font-family: Gotham-light, serif;
    color: #ffffff;
}

.login-route .right-container strong {
    font-family: Gotham-regular, serif;
}

.login-route .header-text {
    font-size: 40px;
    display: flex;
    line-height: 58px;
    flex-direction: column;
}

.login-route .annotation > div {
    margin-bottom: 4px;
}

.login-route .annotation > div label {
    font-family: Gotham-regular, serif;
}

.login-route .annotation > div label:first-child {
    margin-right: 48px;
}

.login-route .annotation > label {
    color: #ffb5c4;
    line-height: 28px;
}