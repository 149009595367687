.App {
    display: grid;
    grid-template-columns: 350px auto;
    min-height: 100vh;
}

.App .content {
    background-color: #FEFEFE;
    justify-self: center;
    width: calc(100% - 128px);
    padding-top: 96px;
}

label.success-message {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    display: block;
}

.item-span-2 {
    grid-column: 1 / span 2; /* Zajistí, že se prvek rozprostírá přes oba sloupce */
}

.formActions .rightSection {
    display: flex;
    gap: 1rem;
}