.RouteStopSelector {
    display: flex;
    justify-content: space-between; /* Rozloží sloupce rovnoměrně a přidá mezi ně mezery */
    align-items: flex-start; /* Zarovná položky na začátku kontejnerů */
    gap: 20px; /* Přidá mezery mezi sloupce */
}

.RouteStopSelector > div {
    flex: 1; /* Každý sloupec bude mít stejnou šířku */
}

.RouteStopSelector h2 {
    border-bottom: 1px solid gray;
}

.RouteStopSelector .allStops {
    padding: 0;
}

.RouteStopSelector svg {
    width: 20px;
    height: 20px;
}

.RouteStopSelector strong {
    font-family: 'Gotham-bold';
}

.RouteStopSelector li {
    font-size: 18px;
    line-height: 1.5;
}

.RouteStopSelector ul {
    min-height: 36px;
    border: 1px solid lightgray;
    padding: 12px 0 12px 40px;
}