.State label::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

.State label.finished::before {
    background-color: #A961CF;
}
.State label.finished {
    color: #A961CF;
}

.State label.inProcess::before {
    background-color: #16DFAB;
}
.State label.inProcess {
    color: #16DFAB;
}
