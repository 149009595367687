.navbar {
    background-color: #1B2338;
}

.navbar > div {
    display: flex;
    flex-flow: column wrap;
    align-content: flex-end;
}

.navbar img {
    display: block;
    margin: 72px auto;
    width: 50%;
}

.navbar-item {
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 16px 24px;
    color: #ffffff;
    cursor: pointer;
    background-color: #111725;
    margin-bottom: 16px;
    width: 70%;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    text-decoration: none;
}

.navbar-item label {
    cursor: pointer;
    font-family: Gotham-regular, serif;
}

.navbar-item svg {
    color: rgba(242, 145, 45, 1);
}

.navbar-item.selected {
    background-color: #FEFEFE;
    color: #111725;
}