.Button {
    border-radius: 40px;
    background-color: #F94340;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    width: 160px;
    height: 48px;
    border: none;
}

.Button.dark {
    background-color: #1B2338;
}

.Button.disabled {
    cursor: not-allowed;
}