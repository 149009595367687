.logged-user {
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: -6px 6px 20px 0 rgba(14, 30, 37, .2);
    border-radius: 0 0 0 10px;
    display: flex;
    align-items: center;
}

.logged-user .container {
    display: flex;
    flex-direction: column;
    padding: 0 16px 0 24px;
}

.logged-user .container label:first-child {
    font-size: 18px;
    color: #FB4E2C;
}

.logged-user .container label:last-child {
    font-size: 12px;
}

.logged-user .icon {
    color: #fff;
    padding: 18px;
    background-color: #F94340;
    border-bottom-left-radius: 10px;
}

.logged-user .icon {
    cursor: pointer;
}