.Vehicles tbody tr td a.action1 {
    background-color: #1B2338;
    color: #ffffff;
    padding: 12px 24px;
    border-radius: 32px;
    font-family: Gotham-regular, serif;
    margin: auto;
}

.Vehicles tbody tr td a.action2 {
    color: #E7EAE1;
    border: 1px solid #E7EAE1;
    padding: 12px 24px;
    border-radius: 32px;
    font-family: Gotham-regular, serif;
    margin: auto;
}