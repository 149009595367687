.VehicleMaintenanceForm {
    margin-top: 48px;
    padding: 24px 32px;
    background-color: #ffffff;
    box-shadow: -6px 6px 20px 0 rgb(14 30 37 / 20%);
    border-radius: 16px;
}

.VehicleMaintenanceForm .formContent {
    display: flex;
}

.VehicleMaintenanceForm .formContent form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 16px;
}

.VehicleMaintenanceForm .formContent .qrCode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
}

.VehicleMaintenanceForm .formContent .qrCode.blur label {
    text-align: center;
}

.VehicleMaintenanceForm .formContent .qrCode.blur svg {
    filter: blur(6px);
}

.VehicleMaintenanceForm .formContent .qrCode svg {
    border: 1px solid #D0D0D0;
    padding: 16px;
}

.VehicleMaintenanceForm .formContent .qrCode label {
    font-size: 14px;
    color: #F94340;
    font-family: Gotham-regular, serif;
    margin-top: 12px;
}

.VehicleMaintenanceForm .formActions {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}