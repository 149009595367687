.form-checkbox {
    font-size: 16px;
    display: grid;
    grid-template-columns: 2em auto;
    gap: 0.5em;
    cursor: pointer;
}

.form-checkbox + .form-checkbox {
    margin-top: 1em;
}

.form-checkbox--disabled {
    color: gray;
    cursor: not-allowed;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: white;

    margin: 0;
    font: inherit;
    width: 24px;
    height: 24px;
    border: 1px solid #DADADA;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #DADADA;
    /* Windows High Contrast Mode */
    background-color: darkslategray;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="checkbox"]:disabled {
    color: gray;
    cursor: not-allowed;
}

.FormCheckboxes > label {
    margin-bottom: .5rem;
    display: block;
}

.FormCheckboxes .choices {
    display: flex;
    gap: 3rem;
}

.FormCheckboxes .choice-label,
.FormCheckboxes .choice-label input {
    cursor: pointer;
}

.FormCheckboxes .choices label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}