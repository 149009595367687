.FormInput label {
    font-family: Gotham-regular, serif;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 800;
}

.required-label::after {
    content: ' *';
    color: red;
    margin-left: 5px;
}